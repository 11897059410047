import React from 'react'
import SpeedBlok from '../SpeedBlok'



function SpeedBlokTwentyFive() {
  return (
    <div>
    <SpeedBlok speedNumber={"25"} />
    </div>
  )
}

export default SpeedBlokTwentyFive