import React from 'react'
import "./Footer.css"
function Footer() {
  return (
    <div className="footer">
    <div className="footerTitle">
    Copyright © BlakBlok Ku Kim 2022
</div>
</div>
  )
}

export default Footer