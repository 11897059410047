import React from 'react'

import SpeedBlok from '../SpeedBlok'

function SpeedBlokFifty() {
  return ( 
    <div>
    <SpeedBlok speedNumber ={"50"}/>
    </div>
  )
}

export default SpeedBlokFifty