import React from 'react'
import SpeedBlok from '../SpeedBlok'


function SpeedBlokOneHundred() {
  return (
    <div>
    <SpeedBlok speedNumber={"100"} />
    </div>
  )
}

export default SpeedBlokOneHundred